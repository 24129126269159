/* src/components/Experience.css */
.experience {
    margin-bottom: 2em;
  }
  .experience h2 {
    color: #555;
  }
  .experience ul {
    list-style: none;
    padding: 0;
  }
  .experience li {
    margin-bottom: 1em;
  }
  