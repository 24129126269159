/* src/components/Contact.css */
.contact {
    text-align: center;
  }
  .contact h2 {
    color: #555;
  }
  .contact p {
    margin: 0.5em 0;
  }
  