/* src/components/Projects.css */
.projects {
    margin-bottom: 2em;
  }
  .projects h2 {
    color: #555;
  }
  .projects ul {
    list-style: none;
    padding: 0;
  }
  .projects li {
    margin-bottom: 1em;
  }
  