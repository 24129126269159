/* src/App.css */
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  color: #333;
}

.App {
  max-width: 800px;
  margin: 0 auto;
  padding: 1em;
}
