/* src/components/About.css */
.about {
    margin-bottom: 2em;
  }
  .about h2 {
    color: #555;
  }
  .about p {
    line-height: 1.6;
  }
  