/* src/components/Header.css */
.header {
    text-align: center;
    margin-bottom: 2em;
  }
  
  .header h1 {
    font-size: 2.5em;
    margin: 0;
  }
  
  .header p {
    font-size: 1.2em;
    color: #555;
  }
  